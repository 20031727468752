<template>
  <div class="page">
    <h2 class="accent--text">
      <v-icon color="primary" class="ml-3" large>mdi-forum</v-icon>
      Manage Complaints.
      <back-button />
    </h2>
    <hr class="my-5 header-divider" />

    <!-- RECORD INFO DIALOG -->
    <record-info />

    <!-- TABLE -->
    <v-data-table
      item-key="id"
      class="elevation-1"
      :loading="table.loading"
      :headers="table.headers"
      :items="records"
      loading-text="Dowenloading Data"
      no-data-text="No Data"
      disable-filtering
      disable-pagination
      disable-sort
      hide-default-footer
    >
      <!-- MESSAGE -->
      <template v-slot:item.message="{ item }">
        {{ item.message ? item.message.substr(0, 25) + "..." : "-" }}
      </template>

      <!-- CONTROLS -->
      <template v-slot:item.controls="{ item }">
        <v-btn
          small
          outlined
          color="accent"
          class="mx-1"
          @click="
            SET_CURRNET_RECORD(item);
            SHOW_RECORD_INFO();
          "
        >
          Details
        </v-btn>

        <!-- <v-btn
          small
          icon
          color="error"
          class="mx-2"
          @click="remove(item)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn> -->

        <v-btn
          small
          icon
          color="info"
          class="mx-1"
          v-if="!item.is_seen"
          @click="markAsSeen(item)"
        >
          <v-icon>mdi-eye</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <!-- PAGINATION -->
    <div class="text-center mt-7">
      <v-pagination
        total-visible="7"
        circle
        :value="parseInt(filters.page)"
        :length="totalPages"
        @input="$router.replace({ query: { ...$route.query, page: $event } })"
      ></v-pagination>
    </div>
  </div>
</template>


<script>
import RecordInfo from "./RecordInfo";
import { mapState, mapMutations } from "vuex";

export default {
  name: "contact-us",
  components: { RecordInfo },

  data: function() {
    return {
      table: {
        headers: [
          {
            value: "from",
            text: "Email"
          },
          {
            value: "subject",
            text: "Subject"
          },
          {
            value: "body",
            text: "Content"
          },
          {
            value: "controls",
            text: "Controls"
          }
        ],
        loading: false
      }
    };
  },

  computed: {
    ...mapState("contactUs", ["records", "filters", "totalPages"])
  },

  watch: {
    $route: {
      async handler(route) {
        if (!route.query.page) {
          await this.$router.replace({ query: { ...route.query, page: 1 } });
          return;
        }

        this.SET_FILTERS(route.query);
        this.fetchRecords();
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    ...mapMutations("contactUs", [
      "SHOW_RECORD_INFO",
      "SET_CURRNET_RECORD",
      "SET_FILTERS"
    ]),

    async fetchRecords() {
      this.table.loading = true;

      await this.$store
        .dispatch("contactUs/fetchRecords")
        .catch(error => {
          this.$bus.$emit("showSnackbar", {
            text: "There was a mistake when rendering the category.",
            color: "red darken-3"
          });
        })
        .finally(() => {
          this.table.loading = false;
        });
    },

    // async remove(selectedRecord) {
    //   const confirmMessageParams = [
    //     `حذف الرسالة رقم ${selectedRecord.id}!`,
    //     `هل أنت متأكد من حذف الرسالة؟`,
    //     { color: 'error' }
    //   ];
    //   if (!await this.$root.$confirm(...confirmMessageParams)) return;

    //   this.table.loading = true;

    //   this.$store.dispatch('contactUs/delete', { recordId: selectedRecord.id })
    //     .then(() => {
    //       this.$bus.$emit('showSnackbar', {
    //         text: 'تم حذف الرسالة بنجاح',
    //         color: 'success'
    //       });
    //     })
    //     .catch((error) => {
    //       this.$bus.$emit('showSnackbar', {
    //         text: 'خطأ أثناء حذف الرسالة',
    //         color: 'red darken-3'
    //       });
    //     })
    //     .finally(() => {
    //       this.table.loading = false;
    //     });
    // },

    markAsSeen(recordId) {
      this.table.loading = true;

      this.$store
        .dispatch("contactUs/markAsSeen", recordId.id)
        .then(() => {
          this.fetchRecords();

          this.$bus.$emit("showSnackbar", {
            text: "The message was updated to be read.",
            color: "success"
          });
        })
        .catch(error => {
          // // console.log(error, error.response);
          this.$bus.$emit("showSnackbar", {
            text: "There was a mistake when updating the message to be read.",
            color: "red darken-3"
          });
        });
    }
  }
};
</script>

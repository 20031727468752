<template>
  <v-dialog
    :value="showRecordInfo"
    @input="v => v || HIDE_RECORD_INFO()"
    max-width="600px"
    scrollable
  >
    <v-card>
      <v-card-text class="pt-3 pb-2">
        <v-container>
          <h5 class="mb-2">Message Number</h5>
          <p class="grey lighten-3 pa-2">{{ currentRecord.id }}</p>

          <h5 class="mb-2">Email</h5>
          <p class="grey lighten-3 pa-2">{{ currentRecord.from }}</p>

          <h5 class="mb-2"> Complaint Subject</h5>
          <p class="grey lighten-3 pa-2">{{ currentRecord.subject }}</p>

          <h5 class="mb-2">Complaint</h5>
          <p class="grey lighten-3 pa-2">
            <template v-if="currentRecord.body">{{ currentRecord.body }}</template>
            <template v-else> Not exist</template>
          </p>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>


<script>
import { mapState, mapMutations } from "vuex";

export default {
  name: "record-info",


  computed: {
    ...mapState("contactUs", ['showRecordInfo', 'currentRecord'])
  },


  methods: {
    ...mapMutations("contactUs", ["HIDE_RECORD_INFO"]),
  }
};
</script>
